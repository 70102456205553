import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { OrderDetails } from '../../../types/OrderDetails'
import { useBuyerVerification } from '../../../custom-hooks/useBuyerVerification'
import { useUpdatePaymentJourneyInfo } from '../../../custom-hooks/useUpdatePaymentJourney'
import useFetchCacheData from '../../../custom-hooks/useFetchCacheData'
import { BusinessType } from '../../../types/enums/BusinessType'
import { defaultCountryCode } from '../../../consts'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@mui/material/Typography'
import PannelWithDefaultButtons from '../../../components/common/button/PannelWithDefaultButtons'
import { PaymentJourneyInfo } from '../../../types/PaymentJourneyInfo'
import CreditCheck from '../../../components/creditCheck/CreditCheck'

type SoleTraderDetailsForm = {
  email?: string
  phoneNumber?: string
}

export type SoleTraderDetailsProps = {
  orderInfo: OrderDetails
  onSaveuserMutationSuccess: (data: PaymentJourneyInfo) => void
}

const SoleTraderDetails = ({ orderInfo, onSaveuserMutationSuccess }: SoleTraderDetailsProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const paymentJourneyData = useFetchCacheData()

  const form = useForm<SoleTraderDetailsForm>({
    mode: 'onChange',
    defaultValues: {
      email: paymentJourneyData?.orderDetails?.company?.companyIdentifier
    }
  })

  const { handleSubmit } = form

  const buyerVerificationMutation = useBuyerVerification({
    onSuccess: (response) => {
      if (response.status === 200) {
        if (response.onboardingRedirectUrl) {
          window.location.replace(response.onboardingRedirectUrl)
        } else {
          saveUserMutation.mutate({
            orderDetails: {
              ...orderInfo,
              company:
                orderInfo.company != null
                  ? orderInfo.company
                  : {
                      companyIdentifier: paymentJourneyData!.orderDetails.company.companyIdentifier!,
                      type: BusinessType.SoleTrader,
                      countryOfRegistration: defaultCountryCode
                    }
            },
            journey: {}
          })
        }
      }
    }
  })

  const saveUserMutation = useUpdatePaymentJourneyInfo({
    onSuccess: onSaveuserMutationSuccess
  })

  const onSubmit = () => {
    setLoading(true)
    buyerVerificationMutation.mutate(paymentJourneyData!.orderDetails.company.companyIdentifier!)
  }

  return (
    <form id="verification-form" onSubmit={handleSubmit(onSubmit)}>
      <FormControl sx={{ pb: '0' }}>
        <FormLabel sx={{ typography: 'inputLabel' }}>Sole trader's email</FormLabel>
        <Typography data-cy="companyIdentifier" variant="caption2">
          {orderInfo.company.companyIdentifier}
        </Typography>
      </FormControl>
      <CreditCheck />
      <PannelWithDefaultButtons
        marketplaceName={paymentJourneyData?.marketplace.name as string}
        redirectUrl={paymentJourneyData?.paymentJourney.paymentCancelledRedirectUrl as string}
        loading={loading}
        disabled={!form.formState.isValid}
      />
    </form>
  )
}

export default SoleTraderDetails
