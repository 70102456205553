import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders } from 'axios'
import { trackAxiosError } from '../utils/metrics/appInsights/AppInsights'

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  trackAxiosError(error)
  return Promise.reject(error)
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  trackAxiosError(error)
  return Promise.reject(error)
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(undefined, onRequestError)
  axiosInstance.interceptors.response.use(undefined, onResponseError)
  return axiosInstance
}
