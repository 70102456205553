import React, { useEffect } from 'react'
import useFetchCacheData from '../../custom-hooks/useFetchCacheData'
import { useNavigate } from 'react-router-dom'
import Loading from '../../components/common/loading/Loading'
import { delay } from '../../utils/delay'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'

const MfaApproved = () => {
  useVirtualPageView(VirtualPage.MFA_APPROVED)
  const navigate = useNavigate()
  const paymentJourneyData = useFetchCacheData()
  const redirectToError = () => {
    navigate('/error', {
      replace: true
    })
  }

  useEffect(() => {
    if (!paymentJourneyData) {
      return
    }

    // 4 seconds, as no request needs to be done before redirecting
    // Allows the user enough time to read the text
    delay(4000)
      .then(() => {
        window.location.replace(paymentJourneyData.paymentJourney.paymentAcceptedRedirectUrl!)
      })
      .catch(() => redirectToError())
  }, [paymentJourneyData])

  if (!paymentJourneyData) {
    return null
  }

  return (
    <Loading
      header={`The order has been approved`}
      bodyMessage={`We're redirecting you to ${paymentJourneyData.marketplace.name}`}
      addendumMessage={`Please don't close the window.`}
    ></Loading>
  )
}

export default MfaApproved
