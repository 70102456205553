import React, { useEffect, useRef, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Loading from '../../components/common/loading/Loading'
import { BusinessType } from '../../types/enums/BusinessType'
import SoleTraderDetails from './components/SoleTraderDetails'
import { TerminalPaymentJourneyStatuses, PaymentJourneyStatus } from '../../types/enums/PaymentJourneyStatus'
import { PaymentJourneyInfo } from '../../types/PaymentJourneyInfo'
import CustomerDetailsWrapper from './components/CustomerDetailsWrapper'
import LimitedCompanyDetails from './components/LimitedCompanyDetails'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../types/enums/VirtualPage'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { EventTypes } from '../../types/enums/TrackEventType'
import { BusinessTypeContext } from './BusinessTypeContext'
import UserIdentification from './components/UserIdentification'
import CompanyStructure from './components/CompanyStructure'
import Box from '@mui/material/Box'
import theme from '../../assets/theme'

const CustomerDetails: React.FC<PaymentJourneyInfo> = (data) => {
  useVirtualPageView(VirtualPage.CUSTOMER_DETAILS)
  const trackEvent = useTrackEvent()
  const { shortCode } = useParams()
  const orderInfo = data.orderDetails
  const navigate = useNavigate()
  const [companyType, setCompanyType] = useState<string | undefined>(data.orderDetails.company?.type)
  const isEngagementThresholdMet = useRef<boolean>(false)
  const isCustomerDetailsExists =
    !!data.orderDetails.company?.kriyaCompanyIdentifier ||
    !!data.orderDetails.user.email ||
    !!data.orderDetails.user.phoneNumber

  const onSaveUserMutationSuccess = (data: PaymentJourneyInfo) => {
    if (TerminalPaymentJourneyStatuses.includes(data.paymentJourney.status as PaymentJourneyStatus)) {
      return <Loading />
    }
    navigate(`/${shortCode as string}/payment`)
  }

  useEffect(() => {
    if (!isEngagementThresholdMet.current) {
      const timer = setTimeout(() => {
        isEngagementThresholdMet.current = true
        trackEvent(EventTypes.CUSTOMER_DETAILS.ENGAGEMENT_MET)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [trackEvent])

  // non-supported scenario
  if (
    data.paymentJourney.shouldCollectCompanyDetails &&
    data.marketplace.supportedCompanies?.length === 1 &&
    data.marketplace.supportedCompanies?.indexOf(BusinessType.SoleTrader) >= 0
  ) {
    return <Navigate to={'/error'} />
  }

  if (!isCustomerDetailsExists) {
    return (
      <CustomerDetailsWrapper data={data}>
        <Box>
          <Box sx={{ mb: theme.spacing(9) }} typography="caption">
            Welcome to Kriya
          </Box>
          <UserIdentification availableCountries={data.paymentJourney.availableCountries}></UserIdentification>
        </Box>
      </CustomerDetailsWrapper>
    )
  }

  return (
    <BusinessTypeContext.Provider value={{ companyType, setCompanyType }}>
      <CustomerDetailsWrapper data={data}>
        {data.paymentJourney.shouldCollectCompanyDetails && companyType === undefined && (
          <Box>
            <Box sx={{ mb: theme.spacing(7) }} typography="caption">
              Welcome to Kriya
            </Box>
            <Box typography="body1" sx={{ mb: theme.spacing(5) }}>
              Before we complete checkout, we need to run a few checks.
            </Box>
            <Box typography="body1" sx={{ mb: theme.spacing(11) }}>
              Please provide the following information to verify your eligibility.
            </Box>
            <CompanyStructure
              marketplaceName={data.marketplace.name}
              redirectUrl={data.paymentJourney.paymentCancelledRedirectUrl as string}
            />
          </Box>
        )}
        {companyType && companyType.toLowerCase() !== BusinessType.SoleTrader.toString().toLowerCase() && (
          <Box>
            <Box sx={{ mb: theme.spacing(5) }} typography="caption">
              Unlock your spending limit
            </Box>
            <Box typography="body1" sx={{ mb: theme.spacing(11) }}>
              To unlock your spending limit, we need some more information about your business.
            </Box>
            <LimitedCompanyDetails data={data} onSaveUserMutationSuccess={onSaveUserMutationSuccess} />
          </Box>
        )}
        {companyType && companyType.toLowerCase() === BusinessType.SoleTrader.toString().toLowerCase() && (
          <SoleTraderDetails orderInfo={orderInfo} onSaveuserMutationSuccess={onSaveUserMutationSuccess} />
        )}
      </CustomerDetailsWrapper>
    </BusinessTypeContext.Provider>
  )
}

export default CustomerDetails
